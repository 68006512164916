import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"

import "./header.css"

import Logo from "../images/ANXTopLogo.png"
import ICBack from "../images/ic/backarrow.png"

const BackBTN = () => (
  <Location>
    {({ location }) => {
      console.log(location.pathname)
      if (
        location.pathname.includes("/hello") ||
        location.pathname.includes("/prayer") ||
        location.pathname.includes("/sermons") ||
        location.pathname.includes("/outreachproject") ||
        location.pathname.includes("/anxgroups") ||
        location.pathname.includes("/guidedprayer")
      ) {
        return (
          <div id="back-btn">
            <Link to="/">
              <img src={ICBack} alt="back button" />
              Back
            </Link>
          </div>
        )
      }
    }}
  </Location>
)

const Header = ({ siteTitle }) => (
  <header className="cardshadow">
    <div id="header-content">
      <BackBTN />
      <div style={{}}>
        <img src={Logo} alt="Logo" className="logo" />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
